import {
    getBigCoinList,
    getCoinVersionList,
    getCoinVersionListByPriceQuery, getCustomDynastyListByNameApi,
    getSmallCoinList,
    getSmallCoinListByBigCoin
} from "@/axios/apis"

export default {
    data() {
        return {
            // coinKindId 大币种ID
            // coinKindSid 大币种sid
            // coinKindItemId   小币种ID
            // coinKindItemVersionId  版别ID
            coinList: [],
            bigCoinList: [],
            coinItemList: [],
            smallCoinList: [],
            versionList: [],
            dynastyList: [],
        }
    },
    methods: {
        /** 获取分类列表 */
        async getCurrencyTypeList() {
            await this.axios("/dq_admin/category/list").then((res) => {
                const { records } = res.data;
                this.coinCategoryList = records || [];
            });
        },
        /** 大币种改变 */
        async changeCoin(e, type) {
            delete this.params.coinKindItemId;
            delete this.params.coinKindItemVersionId;
            delete this.params.versionId;
            if (!this.params.coinKindId) return;
            const coin = this.coinList.find((el) => {
                return el.id === this.params.coinKindId;
            });
            this.$set(this.params, "coinSid", coin.sid);
            this.$set(this.params, "coinKindName", coin.coinKindName);
            if (type) {
                await this.coinItemSearchByBigCoinIdOrName(type)
            } else {
                await this.coinItemSearch();
            }
        },
        /** 选择小币种 */
        async changeCoinItem() {
            delete this.params.coinKindItemVersionId;
            delete this.params.versionId;
            if (!this.params.coinKindItemId) return;
            await this.getVersionType(this.params.coinKindId, this.params.coinKindItemId)
        },
        /** 选择版别 */
        async changeItemVersion(val) {
            if (!val) return;
            const version = this.versionList.find((el) => {
                return el.coinVersionId === this.params.versionId || el.coinVersionId === this.params.coinKindItemVersionId;
            });
            this.params.versionId = val;
            this.params.coinKindItemVersionId = val;
            this.params.coinKindItemVersionName = version.coinVersionName;
            this.$forceUpdate()
        },

        /** 搜索，大币种搜索
         * @param str 搜索名称
         * @param type 搜索阶段类型
         * @param notCoinTypeList Array<number>  大币种类型，1：古钱币，2：银元，3：铜元，5：花钱，6：邻国，7：杂项，8：近代币，9：纸币
         * */
        async coinSearch(str, type, notCoinTypeList) {
            if (!str) return;
            const res = await getBigCoinList(str, notCoinTypeList)
            if (res.status !== "200") return;
            const { records } = res.data;
            this.coinList = records;
            this.bigCoinList = records
            if (type) {
                if (type === 'setDefault') {
                    await this.coinItemSearchByBigCoinIdOrName(type)
                } else {
                    await this.coinItemSearch(type)
                }
            }
        },

        /** 搜索小币种 +版别 */
        async coinItemSearch(type) {
            const res = await getSmallCoinList(this.params.coinSid || this.params.coinKindSid || this.coinSid)
            if (res.status !== 'SUCCESS') return
            const { coinItem } = res.data;
            this.coinItemList = coinItem;
            this.smallCoinList = coinItem
            if (type) {
                await this.getVersionType(
                    this.params.coinKindId || this.params.coinId,
                    this.params.coinKindItemId || this.params.coinItemId
                )
            }
        },
        /** 搜索小币种，不带版别---根据大币种ID，或大币种名称 */
        async coinItemSearchByBigCoinIdOrName(type) {
            const res = await getSmallCoinListByBigCoin({
                coinId: this.params.coinId || this.params.coinKindId
            })
            if (res.status !== '200') return
            this.coinItemList = res.data.records;
            this.smallCoinList = res.data.records
            if (type) {
                if (type === 'setDefault') {
                    await this.getVersionType(
                        this.params.coinKindId || this.params.coinId,
                        this.params.coinKindItemId || this.params.coinItemId
                    )
                }
            }
        },
        /** 获取版别 */
        async getVersionType(coinId, coinItemId) {
            let res
            if (this.versionSearchType === 'queryPrice') {
                res = await getCoinVersionListByPriceQuery(coinId, coinItemId)
            } else {
                res = await getCoinVersionList(coinId, coinItemId)
            }
            if (res.status !== '200') return;
            this.versionList = res.data;
            this.versionCoinList = res.data;
        },

        /** 获取朝代 */
        async getDynastyByCustomList(nameOrId) {
            const res = await getCustomDynastyListByNameApi(nameOrId)
            if (res.status !== '200') return;
            this.dynastyList = res.data.data.records
        }
    }
}